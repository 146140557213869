.active_photos {
  height: 450px;
  background-color: #f7f7f7;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.active_photos .swiper-slide-active {
  display: flex;
  justify-content: center;
  align-items: center;
}
.active_photos .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.swiper-slide-visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery_photos .swiper-slide-visible {
  border: 1px solid #e7e7e7;
  margin: 0 !important;
  height: 5rem;
  width: 5rem !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  cursor: pointer;
}
.swiper-slide-thumb-active.swiper-slide-visible {
  border: 1px solid #4a3e4c;
}
.swiper-free-mode > .swiper-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 1rem;
}
.swiper-slide-prev,
.swiper-slide-next {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* #F7F7F7 backactive image */
