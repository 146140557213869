.chatRoom{
    width: 35%;
    height: 75%;
    position: fixed;
    right: 0;
    bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0px;
    z-index: 99999999;
    .vendorDetails{
        width: 100%;
        height: 10%;
        background-color: #605363;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .user{
            display: flex;
            color: #fff;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
            img{
                width: 50px;
                height: 50px;
                padding: 10px;
                object-fit: contain;
                border-radius: 50%;
                background-color: #fbfbfb;
            }
        }
    }
    .chatContent{
        height: 80%;
        background-color: #fbfbfb;
        border: 1px solid #605363;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column-reverse;
    }
    .controller{
        height: 10%;
        padding: 10px 0px;
        padding-left: 5px;
        background-color: #605363;
        display: flex;
        justify-content: center;
        align-items: center;
        .sendBtn{
            outline: none;
            border: none;
        }
    }
}

@media screen and (max-width: 950px) {
    .chatRoom {
        width: 50%;
        height: 75%;
    }
  }

  @media screen and (max-width: 600px) {
    .chatRoom {
        width: 80%;
        height: 65%;
    }
  }