.properties {
  display: flex;
  align-self: self-start;
  margin-bottom: 20px;
  button {
    background-color: #fff;
    color: #4a3e4c;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #4a3e4c;
    padding: 5px 30px;
    &:hover {
      background-color: #4a3e4c;
      color: #fff;
    }
  }
}
