.container {
    .subContainer {
      .vendorsBox {
        flex-wrap: wrap;
        .vendorDiv {
          background-color: #fff;
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }
  
  @media screen and (max-width: 750px) {
    .container {
      .subContainer {
        .vendorsBox {
          justify-content: center;
          align-items: center;
          .vendorDiv {
            width: 40%;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 500px) {
    .container {
      .subContainer {
        .vendorsBox {
          justify-content: center;
          align-items: center;
          .vendorDiv {
            width: 80%;
          }
        }
      }
    }
  }
  