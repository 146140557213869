.chatMessage{
    width: auto;
    max-width: 60%;
    padding: 10px;
    margin: 5px 10px;
    border-radius: 10px;
    img{
        width: 200px;
        height: 200px;
    }
}

.senderMessage{
    background-color: #f8f6fd;
    color: #605363;
    border: 1px solid #605363;
    margin-left: auto;
}

.recieverMessge{
    background-color: #605363;
    color: #f8f6fd;
    margin-right: auto;
}