.stock {
  display: flex;
  flex-direction: column;
  align-self: self-start;
  width: 100%;
  
  button {
    width: 30%;
    margin-top: 20px;
    background-color: #fff;
    color: #4a3e4c;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    border: 1px solid #4a3e4c;
    // padding: 5px 10px;
    &:hover {
      background-color: #4a3e4c;
      color: #fff;
    }
  }
}

@media screen and (max-width: 650px) {
  .stock {
    button {
      width: 50%;
    }
  }
}
