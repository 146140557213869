.nav {
  background-color: #f8f6fd;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 10px;
  padding-right: 30px;
  .logoBox {
    padding: 10px;
    background-color: #4a3e4c;
    border-radius: 10px;
    img {
      width: 120px;
    }
  }
  .iconsSection {
    .phone_icon_list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      img {
        width: 50px;
        border-radius: 50%;
        background-color: #fff;
      }
      h6{
        color: #4a3e4c;
    }
    }
    display: flex;
    // justify-content: center;
    // align-items: center;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    gap: 25px;
    color: var(--primaryColor);
    &:hover {
      cursor: pointer;
    }
  }
}
.offcanvas_header {
  padding-bottom: 0;
  button {
    margin: 10px !important;
    margin-left: auto !important;
    margin-bottom: 0 !important;
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor) !important;
    font-size: 10px !important;
  }
}
.navLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #bbb7bf;
  a {
    margin: 10px;
    text-decoration: none;
    font-size: 20px;
    color: var(--primaryColor700);
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  a.activeLink {
    // padding: 10px;
    padding-left: 0;
    font-size: 30px;
    font-weight: 700;
    color: #4a3e4c;
  }
}

.downloadMobile {
  margin: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    background-color: var(--primaryColor);
    margin: 10px 0;
    width: 52%;
    img {
      max-width: 30px;
      width: 100%;
      height: 40px;
      margin: 4px 0;
    }
    div {
      white-space: nowrap;
      p:first-child {
        color: var(--primaryColor200);
        font-size: 8px;
      }
      p:last-child {
        color: #fff !important;
        font-size: 10px;
      }
    }
  }
}
