.about_us {
   min-height: 100vh;
   padding-top: 4.3rem;
   @media (max-width: 991px) {
      padding-top: 10rem;
   }
   .head_page {
      background-color: RGBA(74, 62, 76, 0.1);
      .container {
         padding: 2rem 15px;
      }
   }
   .txt {
      padding: 50px 0px 40px;
      line-height: 1.8;
      color: rgb(74, 74, 74);
      font-size: 21px;
   }
}
