.filterIcon {
   display: none;
   @media only screen and (max-width: 991px) {
      position: fixed;
      top: 50%;
      right: 10px;
      background-color: #403542;
      width: 50px;
      height: 50px;
      z-index: 99;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
         transform: scale(1.1);
      }
      svg {
         color: #fff;
         font-size: 30px;
      }
   }
}

.side__filter {
   @media only screen and (max-width: 991px) {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      transition: all 0.1s;
      height: 100vh;
      transform: translateX(100%);
      overflow-y: scroll;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      z-index: 99;
      width: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 5rem 0 0;
      .accordion {
         width: 300px;
      }
      .close_filter {
         background-color: #fff;
         display: flex;
         justify-content: center;
         align-items: center;
         border-radius: 50%;
         margin-bottom: 10px;
         padding: 10px;
         cursor: pointer;
         transition: all 0.3s;
         &:hover {
            transform: scale(1.1);
         }
         svg {
            font-size: 30px;
         }
      }
   }
}
.show_filter {
   transform: translateX(0%);
   opacity: 1;
}
.close_filter {
   display: none;
}
