.form {
  width: 100%;
  margin-bottom: 20px;
  .row {
    display: flex;
    width: 100%;
    gap: 20px;
    margin: 20px 0px;
    div {
      width: 100%;
      p {
        span {
          color: red;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .form {
    .row {
      display: block;
      margin: 0px;
      div{
        margin: 20px 0px;
      }
    }
  }
}

