.terms {
   min-height: 100vh;
   padding-top: 4.3rem;
   @media (max-width: 991px) {
      padding-top: 10rem;
   }
   .head_page {
      background-color: RGBA(74, 62, 76, 0.1);
      .container {
         padding: 2rem 15px;
      }
   }
   .desc {
      font-size: 17px;
      line-height: 1.8;
      font-weight: 600;
      text-align: justify;
      margin-bottom: 5rem;
   }
   .title_txt {
      text-decoration: underline;
   }
}
