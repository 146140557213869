.container {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .row {
    width: 70%;
  }
}

@media screen and (max-width: 650px) {
  .container {
    .row {
      width: 90%;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .row {
      width: 100%;
    }
  }
}
