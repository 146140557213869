.container{
    display: flex;
    margin-top: 70px;
    .sideBar{
        width: 20%;
        padding: 20px 10px;
        .vendor{
            background-color: #f8f6fd;
            margin-bottom: 40px;
            padding: 10px;
            display: flex;
            gap: 10px;
            border-radius: 10px;
            img{
                width: 50px;
                border-radius: 50%;
            }
        }
    }
    .modileMenu{
        display: none;
      }
    .contentContainer{
        width: 80%;
        padding: 10px;
        .pageTitle{
            font-size: 28px;
            font-weight: 600;
            color: #4b3d4d;
        }
        .content{
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 950px){
    .container{
        display: block;
      .sideBar{
        display: none;
        width: 0%;
      }
      .contentContainer{
        width: 100%;
      }
      .modileMenu{
        display: block;
        margin-top: 150px;
      }
    }
  
  }