.lists_prog {
  z-index: 1;
}

.progress_bar_track {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 15px 15px 0;
  table-layout: fixed;
  margin-bottom: 2rem;
}
.progress_bar_track li {
  list-style-type: none;
  display: table-cell;
  width: 25%;
  float: left;
  font-size: 16px;
  position: relative;
  text-align: center;
}
.circll {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -75%);
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 4px solid #fff;
  -webkit-transform: translate(-50%, -75%);
  -moz-transform: translate(-50%, -75%);
  -ms-transform: translate(-50%, -75%);
  -o-transform: translate(-50%, -75%);
  padding: 5px;
}
.progress_bar_track li::before {
  width: 50px;
  height: 50px;
  color: #fff;
  content: "";
  line-height: 50px;
  font-size: 18px;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #dfe4ff;
}
.progress_bar_track li::after {
  width: 100%;
  height: 10px;
  content: "";
  position: absolute;
  background-color: #dfe4ff;
  top: 20px;
  left: -50%;
  z-index: -1;
}
.progress_bar_track li:first-child:after {
  content: none;
}
.progress_bar_track li.step-done {
  color: #fff;
}
.progress_bar_track li.step-done::before {
  background-color: #4a3e4c;
  color: #fff;
  content: "\f00c";
  font-family: "FontAwesome";
  font-weight: 700;
  font-size: 25px;
}
.progress_bar_track li.step-done + li:after {
  background-color: #4a3e4c;
}
.progress_bar_track li.step-done .circll {
  display: none;
}
