.contact {
   min-height: 100vh;
   padding-top: 4.3rem;
   @media (max-width: 991px) {
      padding-top: 10rem;
   }
   .head_page {
      background-color: RGBA(74, 62, 76, 0.1);
      .container {
         padding: 2rem 15px;
      }
   }
   .btn_submit {
      background-color: #403542;
      color: #fff;
      &:hover {
         background-color: #605363;
      }
   }
}
