.container {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .content{
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    .properties{
      display: flex;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
      // margin-top: 10px;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 20px;
      .property{
        width: 30%;
        background-color: #fbfbfb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 5px;
      }
    }

    button {
      margin-top: 20px;
      background-color: #4a3e4c;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid #4a3e4c;
      padding: 5px 30px;
      &:hover {
        background-color: #fff;
        color: #4a3e4c;
      }
    }
  }
}
