.container {
    width: 100%;
    display: flex;
    flex-direction: column;

  .sizeContainer {
    display: flex;
    gap: 10px;
    width: 100%;
    div {
      width: 100%;
    }
  }
  .colorBtn {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    background-color: #fff;
    color: #4a3e4c;
    padding: 10px 3px;
    &:hover {
      background-color: #fbfbfb;
    }
  }
  button {
    width: 50%;
    margin: auto;
    margin-top: 20px;
    background-color: #4a3e4c;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #4a3e4c;
    padding: 5px 10px;
    // &:hover {
    //   background-color: #4a3e4c;
    //   color: #fff;
    // }
  } 
  .colorsContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    p{
        background-color: #fbfbfb;
        width: 30%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
  }
}
